import React from "react";

import { useTranslation } from "react-i18next";
import classnames from "classnames/dedupe";

import { GT } from "components/index";

interface ButtonProps {
    disabled?: boolean;
    icon: string;
    text: string;
    onClick: (e) => void;
}

export const Button = (props: ButtonProps) => {
    const { disabled, icon, text, onClick } = props;

    const { t } = useTranslation();

    return (
        <div className="role-filters-btn-container">
            <div className="dummy"></div>
            <div className={classnames("role-filters-btn", { disabled })} role="button" onClick={onClick}>
                <GT.Icon name={icon} />
                {t(text)}
            </div>
        </div>
    );
};
