import React, { ReactNode, useEffect, useRef, useState } from "react";

import Icon from "components/icon";

import "./navbar-dropdown-toggle.styles.scss";

interface INavbarDropdownToggle {
    quantity: number | undefined;
    icon: string | ["fas" | "gt", string];
    children: (closeDropdown: () => void, ref: React.RefObject<HTMLDivElement>) => ReactNode;
}

export const NavbarDropdownToggle = (props: INavbarDropdownToggle) => {
    const { quantity = 0, children, icon } = props;

    const [toggle, setToggle] = useState<boolean>(false);

    const buttonRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const clickOutside = (e) => {
            if (!childRef.current?.contains(e.target) && !buttonRef.current?.contains(e.target)) setToggle(false);
        };

        document.addEventListener("click", clickOutside);

        return () => document.removeEventListener("click", clickOutside);
    }, []);

    return (
        <>
            <div className="gt-navbar-dropdown-toggle" role="button" onClick={() => setToggle((prev) => !prev)} ref={buttonRef}>
                <div className="navbar-dropdown-toggle">
                    <Icon name={icon} />
                    {!!quantity && <span className="navbar-dropdown-toggle-badge">{quantity}</span>}
                </div>
            </div>
            {toggle && children(() => setToggle(false), childRef)}
        </>
    );
};

export const calculatePositionFromRightScreenBorder = () => {};
