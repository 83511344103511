import React from "react";

import { onDeleteAdditionalColumn } from "./onDeleteAdditionalColumn";
import { MenuItem, MenuItemCommandParams } from "primereact/menuitem";
import { ContextMenu } from "primereact/contextmenu";
import { TFunction } from "react-i18next";

import { TooltipMenuItem } from "../components/contextMenu/components/tooltip-menu-item";
import { handleExportCsv, handleExportRawCsv } from "./export-csv";

import * as I from "../interfaces";
import * as CI from "../interfaces/context-interfaces";
import hasPermission from "utils/hasPermissions";

interface ICommands extends Omit<I.IContextHandlers, "onContextColumnFieldChange"> {
    openModal: (modalName: CI.ContextMenuModalsTypes, additionalData?: any) => void;
}

export interface IValues extends I.IContextEnabledFunctionalities {
    auditData: I.IAuditData | undefined;
    dataSet: any;
    visibleColumns: I.IGeneratedPrimeTableColumn[];
    tableConfig: I.ITableConfig;
    columnField: I.ColumnFieldType;
    multiSort: I.DataTableSortMeta | undefined;
    meta: I.IMeta | null | undefined;
    contextMenuRef: React.RefObject<ContextMenu>;
    contextMenuItems: MenuItem[];
    metaAppTypes: I.IPrimeContextMenuMetaAppTypes[] | null | undefined;
}

export const generateMenuModel = (t: TFunction<string>, commands: ICommands, values: IValues) => {
    const {
        openModal,
        handleRestoreColumns,
        handleGroupBy,
        handleHideColumns,
        handleToggleFilters,
        handleFreezeColumn,
        handleAdditionalColumns,
    } = commands;

    const {
        additionalColumnsEnabled,
        auditData,
        exportCSVEnabled,
        dataSet,
        visibleColumns,
        filterEnabled,
        tableConfig,
        hidingColumnEnabled,
        groupRowsEnabled,
        frozenColumnEnabled,
        columnField,
        multiSort,
        translateDisabled,
        meta,
        contextMenuItems,
        contextMenuRef,
        metaAppTypes,
        roleFilter,
    } = values;

    let menuModel: MenuItem[] = [
        {
            label: t("Audit"),
            icon: "pi pi-fw pi-book",
            command: () => openModal("auditData"),
            disabled: !auditData,
        },
        {
            label: t("Visible data to CSV"),
            icon: "pi pi-fw pi-download",
            disabled: !exportCSVEnabled,
            items: [
                {
                    label: t("Visible data to CSV"),
                    command: () => handleExportCsv(dataSet, visibleColumns, t),
                },
                {
                    label: t("Raw data to CSV"),
                    command: () => handleExportRawCsv(dataSet),
                },
            ],
        },
        {
            label: t("Settings"),
            icon: "pi pi-fw pi-cog",
            disabled: !hidingColumnEnabled && !groupRowsEnabled && !filterEnabled && !frozenColumnEnabled,
            items: [
                {
                    label: t("Pinning columns"),
                    command: () => openModal("freezeColumn"),
                    disabled: !frozenColumnEnabled,
                },
                {
                    label: t("Columns visibility"),
                    command: () => openModal("hideColumns"),
                    disabled: !hidingColumnEnabled,
                },
                {
                    label: t("Group by columns"),
                    command: () => openModal("groupRows"),
                    disabled: !groupRowsEnabled,
                },
                {
                    label: t("Show filters"),
                    command: () => handleToggleFilters(),
                    disabled: !filterEnabled || tableConfig.filtersEnabled,
                },
                {
                    label: t("Hide filters"),
                    command: () => handleToggleFilters(),
                    disabled: !filterEnabled || !tableConfig.filtersEnabled,
                },
            ],
        },
        {
            label: t("Column"),
            icon: "pi pi-fw pi-table",
            disabled: columnField === null || (!hidingColumnEnabled && !groupRowsEnabled && visibleColumns.length < 2),
            items: [
                {
                    label: t("Hide"),
                    command: () => handleHideColumns(columnField),
                    disabled: !hidingColumnEnabled || visibleColumns.length < 2,
                },
                {
                    label: t("Group by"),
                    command: () => handleGroupBy && handleGroupBy(columnField),
                    disabled: !groupRowsEnabled,
                },
                {
                    label: t("Pin"),
                    command: () => handleFreezeColumn(columnField),
                    disabled: !frozenColumnEnabled,
                },
                //Additional columns items
                ...(additionalColumnsEnabled
                    ? [
                          {
                              label: t("Add additional"),
                              command: (e) => {
                                  openModal("addAdditionalColumns");
                                  contextMenuRef.current?.hide(e.originalEvent);
                              },
                              disabled: !meta || Object.keys(meta.ExtraFields).length >= 10,
                              className: "visible-disabled-menu-item",
                              template: (item) => (
                                  <TooltipMenuItem
                                      item={item}
                                      text="Add additional"
                                      tooltipText="You can only have 10 additional columns, please delete already existing one, to add a new one."
                                      disabled={!meta || Object.keys(meta.ExtraFields).length >= 10}
                                  />
                              ),
                          },
                          {
                              label: t("Edit additional"),
                              command: () => openModal("editAdditionalColumns", columnField),
                              disabled: !meta || !columnField || !Object.keys(meta.ExtraFields).includes(columnField),
                          },
                          {
                              label: t("Delete additional"),
                              command: () => onDeleteAdditionalColumn(columnField, meta, handleAdditionalColumns),
                              disabled: !meta || !columnField || !Object.keys(meta.ExtraFields).includes(columnField),
                          },
                      ]
                    : []),
            ],
        },
        {
            label: t("Turn off grouping"),
            icon: "pi pi-fw pi-undo",
            command: () => handleGroupBy && handleGroupBy(undefined),
            disabled: !multiSort?.groupByThisField,
        },
        {
            label: t("Detach the column"),
            icon: "pi pi-fw pi-times",
            command: () => handleFreezeColumn(undefined),
            disabled: !tableConfig.frozenColumn,
        },
        {
            label: t("Add translation"),
            icon: "pi pi-fw pi-plus",
            command: () => openModal("translation"),
            disabled: translateDisabled || !meta?.ModelInfo?.isTranslatable,
        },
        ...getContextMenuItems(contextMenuItems, columnField),
        //Meta app types
        ...(!!metaAppTypes
            ? [
                  {
                      label: t("Meta Apps"),
                      icon: "pi pi-fw pi-align-left",
                      disabled: metaAppTypes?.filter((type) => type.status != "draft").length === 0,
                      items: metaAppTypes?.map((item) => ({
                          label: item.name,
                          icon: "pi pi-fw pi-plus",
                          command: () => openModal("createMetaAppRecord", item.name),
                          disabled: item.status == "draft",
                      })),
                  },
              ]
            : []),
        {
            label: t("Restore default columns"),
            icon: "pi pi-fw pi-refresh",
            command: () => handleRestoreColumns(),
            disabled: !hidingColumnEnabled,
        },
        ...(roleFilter && hasPermission("change.other.table-filters-per-role.change")
            ? [
                  {
                      label: t("Configure filters"),
                      icon: "pi pi-fw pi-cog",
                      command: () => openModal("roleFilters"),
                      disabled: !hidingColumnEnabled,
                  },
              ]
            : []),
    ];

    return menuModel;
};

const getContextMenuItems = (contextMenuItems: MenuItem[], columnField: I.ColumnFieldType): MenuItem[] => {
    return contextMenuItems.map((item) => ({
        ...item,
        command: (e) => {
            item.command && item?.command({ ...e, columnField: columnField } as MenuItemCommandParams);
        },
    }));
};
