import React from "react";

import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import i18n, { timeAgoLangs } from "../../i18n";
import { useHistory } from "react-router-dom";
import classnames from "classnames/dedupe";
import TimeAgo from "react-timeago";
import DOMpurify from "dompurify";
import Axios from "axios";

import { INotification } from "./notifications-dropdown";
import { handleRequest } from "utils/handleRequest";
import Icon from "components/icon";

interface INotificationProps {
    notification: INotification;
    setData: React.Dispatch<React.SetStateAction<any>>;
    closeDropdown: () => void;
}
export const Notification = ({ notification, setData, closeDropdown }: INotificationProps) => {
    const history = useHistory();

    const handleDismiss = async (e, redirect: boolean = false) => {
        e.stopPropagation();

        await handleRequest({
            request: () => Axios.patch(`notifications/${notification?.id}/deactivate`),
            onSuccess: () => {
                setData((prev) => ({ data: prev.data.filter((n) => n.id != notification?.id) }));
                if (redirect) {
                    history.push(notification.redirectionPath);
                    closeDropdown();
                }
            },
        });
    };

    return (
        <div className={classnames("notification", { active: notification.isActive })} onClick={(e) => handleDismiss(e, true)}>
            <header className="notification-message-header">
                <span className="title">{notification?.title}</span>
                <div role="button" className="dismiss-notification" onClick={handleDismiss}>
                    <Icon name="x" />
                </div>
            </header>
            <span className="notification-content" dangerouslySetInnerHTML={{ __html: DOMpurify.sanitize(notification?.content) }}></span>
            <TimeAgo date={notification?.createdDt} formatter={buildFormatter(timeAgoLangs[i18n.language])} />
        </div>
    );
};
