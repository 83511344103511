import { ISelectedNodes, MyTreeNode } from "./interfaces/tree-interfaces";

interface ISelectedRecord {
    id: number;
    [key: string]: any;
}

export const generateSelectedNodes = (initSelectedNodes: ISelectedRecord[] | undefined): ISelectedNodes => {
    let selectedNodes = {};
    initSelectedNodes?.forEach((item) => (selectedNodes = { ...selectedNodes, [item.id]: { checked: true } }));
    return selectedNodes;
};

export const partialNodeToFully = (selected: ISelectedNodes): ISelectedNodes => {
    const _selected = {};

    Object.keys(selected).forEach((key) => {
        _selected[parseInt(key)] = { checked: selected[parseInt(key)].checked || selected[parseInt(key)].partialChecked == true };
    });

    return _selected;
};

export const findNode = (nodes?: MyTreeNode[], givenKey?: string) => {
    if (!nodes?.length) return;
    let childrenNodes: any[] = [];
    const node = nodes.find((node) => {
        if (Number(givenKey) === node.key) return true;
        childrenNodes = [...childrenNodes, ...node.children];
        return false;
    });
    if (!node) return findNode(childrenNodes, givenKey);
    return node;
};
