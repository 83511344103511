import { getColumnToGroupBy, getVisibleAndHiddenColumns } from "./context-menu-helpers";
import { handleRequest } from "utils/handleRequest";
import Axios from "axios";
import { I } from "..";

export const onDeleteAdditionalColumn = async (
    columnField: I.ColumnFieldType,
    meta: I.IMeta | null | undefined,
    handleAdditionalColumns: I.HandleAdditionalColumns | undefined
) => {
    if (!columnField || !handleAdditionalColumns) return;

    let _extraFields: I.ExtraFields = { ...meta?.ExtraFields };

    delete _extraFields[columnField];
    await handleRequest({
        request: () =>
            Axios.post("upsert-extra-fields-info", {
                info: _extraFields,
                modelLabel: meta?.ModelInfo?.appModelLabel,
            }),
        onSuccess: () =>
            handleAdditionalColumns({
                payload: { extraField: columnField as I.ExtraFieldsKeys },
                action: I.ADDITIONAL_COLUMNS_EVENT_ACTION.DELETE,
            }),
        sweetConfirmConfig: {
            title: "Delete additional column",
            content: "This operation is not reversible.",
        },
    });
};
