import React from "react";

import { useTranslation } from "react-i18next";
import classnames from "classnames/dedupe";

import { isMobile } from "../../../common-assets/js/rootui-parts/_utility";
import hasPermission from "../../utils/hasPermissions";
import { Tile } from "./tile";

import "./tileview.scss";

interface IProps {
    apps: {
        [key: string]: {
            icon: Array<string>;
            name: string;
            permCode: string;
        };
    };
}

const TileView = ({ apps }: IProps) => {
    const { t } = useTranslation();

    const appsMapped =
        apps &&
        Object.entries(apps)
            .filter(([k, o]: any) => !o.permCode || hasPermission(o.permCode))
            .map(([k, o]: any) => ({
                name: t(o.name),
                url: k,
                icon: o.icon,
            }));

    return (
        <div className={classnames("tiles-wrap", { mobile: isMobile })}>
            {appsMapped?.map((app: any, i) => (
                <Tile key={i} app={app} />
            ))}
        </div>
    );
};

export default TileView;
