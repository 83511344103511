import React, { RefObject, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { XCircle } from "react-feather";

import "./styles.scss";
import { createPortal } from "react-dom";

interface EmptyMessageProps {
    tableRef?: RefObject<HTMLDivElement>;
}

export const EmptyMessage = ({ tableRef }: EmptyMessageProps) => {
    const { t } = useTranslation();

    const element = tableRef?.current?.getElementsByClassName("p-datatable-scrollable-body");

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onScroll = (e) => {
            if (ref?.current) ref.current.style.left = `${e.target.scrollLeft}px`;
        };

        element?.[0]?.addEventListener("scroll", onScroll);

        return () => element?.[0]?.removeEventListener("scroll", onScroll);
    }, []);

    const TABLE_MIN_HEIGHT = 165;

    const BORDER_WIDTH = 2;

    return (
        <>
            <div className="prime-data-table-message-container">
                <div
                    ref={ref}
                    className="prime-data-table-message"
                    style={{
                        height: `${element?.[0]?.clientHeight || TABLE_MIN_HEIGHT}px`,
                        width: `${(tableRef?.current?.clientWidth || 0) - BORDER_WIDTH}px`,
                    }}
                >
                    <XCircle />
                    <p>{t("No records found")}</p>
                </div>
            </div>
        </>
    );
};
