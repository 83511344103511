import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import classnames from "classnames/dedupe";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import { Trans } from "react-i18next";
import { v4 as uuid } from "uuid";
import Cookies from "js-cookie";
import axios from "axios";

import CustomNavbarLaboratoryModal from "../custom-navbar-laboratory/custom-navbar-laboratory";
import { NotificationsDropdown } from "../notifications-dropdown/notifications-dropdown";
import { AsyncTasksDropdown } from "../async-tasks-dropdown/async-tasks-dropdown";
import NavbarShortcuts from "../custom-navbar-shortcuts/custom-navbar-shortcuts";
import { initNavbar } from "../../../common-assets/js/rootui-parts/initNavbar";
import CustomNavbarRoleModal from "../custom-navbar-role/custom-navbar-role";
import { mapStatusesStyles } from "../../utils/mapStatusesStyles";
import { GlobalSearch } from "../global-search/global-search";
import { updateBreadcrumb } from "../../utils/breadcrumbs";
import addToast from "../../utils/addToast";
import Dropdown from "../bs-dropdown";
import Icon from "../icon";

import {
    updateStyles,
    updateAuth as actionUpdateAuth,
    updateOptions as actionUpdateOptions,
    updateSettings as actionUpdateSettings,
    updatePermissions as actionUpdatePermissions,
    updateLanguage as actionUpdateLanguage,
    logOut as logOutReducer,
} from "../../actions";

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

class PageNavbar extends Component {
    constructor(props) {
        super(props);
        window.RootUI.initNavbar();

        this.state = {
            mobileMenuShow: false,
            static: true,
            labName: "",
            changeLabModalOpen: false,
            roleName: "",
            changeRoleModalOpen: false,
        };

        this.logOut = this.logOut.bind(this);
        this.renderSubmenus = this.renderSubmenus.bind(this);
        this.renderRightMenuItems = this.renderRightMenuItems.bind(this);
    }

    componentDidMount() {
        $(document).on("keydown.rui-navbar", (e) => {
            const { mobileMenuShow } = this.state;

            if (mobileMenuShow && e.keyCode === 27) {
                this.setState({
                    mobileMenuShow: !mobileMenuShow,
                });
            }
        });

        $("#navbar-data").data("func", this.setStatic);
        this.checkYaybarHideStatus();
    }

    checkYaybarHideStatus() {
        const YAYBAR = $(".yaybar").data("yaybar");
        const { auth, options } = this.props;

        if (options.yaybarExpand === true) {
            YAYBAR.showYay();
        } else if (options.yaybarExpand === false) {
            YAYBAR.hideYay();
        }
    }

    setStatic = (val) => {
        const _curr = this.state.static;
        this.setState({ static: val == undefined ? _curr : val });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname != this.props.location.pathname) {
            this.setState({ static: true });
        }
    }

    componentWillUnmount() {
        $(document).off("keydown.rui-navbar");
    }

    logOut() {
        const { updateAuth, updatePermissions, logOutReducer } = this.props;

        const clearAuthPromys = new Promise((res, rej) => {
            logOutReducer();
        });

        const csrftoken = Cookies.get("csrftoken");
        const logoutPromys = new Promise((res, rej) => {
            axios.post("logout", undefined, csrftoken ? { headers: { "X-CSRFToken": csrftoken } } : undefined);
        });

        clearAuthPromys.then((_) => logoutPromys);
    }

    changeAppLanguage = async (data) => {
        const { i18n, updateLanguage, updateStyles } = this.props;

        const savedLanguage = this.props.auth.language;

        if (!data.value || savedLanguage == data.value) return;

        i18n.changeLanguage(data.value, () => {
            axios.defaults.headers.common["accept-language"] = data.value;
            updateLanguage(data.value);

            addToast({
                title: "Language changed",
                content: `Language changed to ${data.name} successfully`,
            });

            updateBreadcrumb();
        });

        try {
            const stylesResponse = await axios.get("translated-system-status", {
                params: {
                    noPagination: "True",
                },
            });
            stylesResponse.data?.data?.length && updateStyles(mapStatusesStyles(stylesResponse.data.data));
        } catch (err) {
            console.error(err);
        }
    };

    renderSubmenus(nav, isMobile, level = 1) {
        return Object.keys(nav).map((url) => {
            const data = nav[url];

            const isActive = window.location.pathname === url;

            const LinkContent = data.name ? (
                <>
                    {data.icon ? (
                        <>
                            <Icon name={data.icon} />
                            <span>{data.name}</span>
                            <span className={data.sub ? "rui-dropdown-circle" : "rui-nav-circle"} />
                        </>
                    ) : (
                        data.name
                    )}
                </>
            ) : (
                ""
            );

            return (
                <React.Fragment key={`${url}-${data.name}`}>
                    {data.sub ? (
                        <Dropdown
                            tag="li"
                            className={classnames(isActive ? "active" : "")}
                            direction={level === 1 ? "up" : "right"}
                            openOnHover={!isMobile}
                            showTriangle
                        >
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                                {LinkContent}
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                                {this.renderSubmenus(data.sub, isMobile, level + 1)}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <li className={classnames("nav-item", isActive ? "active" : "")}>
                            <Link to={data.sub ? "#" : url} className="nav-link">
                                {LinkContent}
                            </Link>
                        </li>
                    )}
                </React.Fragment>
            );
        });
    }

    renderRightMenuItems(isMobile) {
        const { settings, updateSettings } = this.props;
        const countriesDropdown = settings.languages;

        return (
            <>
                <li className="nav-item">
                    <GlobalSearch />
                </li>
                {/* <li className="nav-item">
                    <FancyBox
                        tagName="a"
                        className={classnames("d-flex", isMobile ? "nav-link" : "")}
                        touch={false}
                        closeExisting
                        autoFocus
                        parentEl={null}
                        popupClassName="rui-popup rui-popup-search container"
                        popupRender={() => <PagePopupSearch settings={settings} />}
                    >
                        {isMobile ? (
                            <>
                                <Icon name="search" />
                                <span>Search</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="search" />
                            </span>
                        )}
                    </FancyBox>
                </li> */}
                <Dropdown tag="li" direction="up" openOnHover={!isMobile} showTriangle>
                    <Dropdown.Toggle tag="div" className={classnames("dropdown-item", isMobile ? "nav-link" : "")}>
                        {isMobile ? (
                            <>
                                <Icon name="flag" />
                                <span>Language</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="flag" />
                            </span>
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        tag="ul"
                        className="nav dropdown-menu rui-navbar-dropdown-language"
                        modifiers={{ offset: { offset: "0,12" } }}
                    >
                        {countriesDropdown.map((data) => (
                            <li key={data.name}>
                                <Link
                                    to={window.location.pathname}
                                    onClick={() => this.changeAppLanguage(data)}
                                    className={`rui-navbar-language ${data.value == this.props.auth.language ? "current" : ""}`}
                                >
                                    <span className="rui-navbar-language-img">
                                        <img src={data.icon} alt={data.name} />
                                    </span>
                                    <Trans className="rui-navbar-language-text">{data.name}</Trans>
                                </Link>
                            </li>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                {/* to prevent double mount*/}
                {/* isMobile  passed for future mobile support*/}
                {!isMobile && <NotificationsDropdown />}
                {!isMobile && <AsyncTasksDropdown />}
                {!isMobile && (
                    <CustomNavbarLaboratoryModal
                        labName={this.props.auth.laboratory}
                        modalOpen={this.state.changeLabModalOpen}
                        closeModal={() => {
                            this.setState({ changeLabModalOpen: false });
                        }}
                    />
                )}
                {!isMobile && (
                    <CustomNavbarRoleModal
                        roleName={this.props.auth.role}
                        modalOpen={this.state.changeRoleModalOpen}
                        closeModal={() => {
                            this.setState({ changeRoleModalOpen: false });
                        }}
                    />
                )}
                {!isMobile ? (
                    <Dropdown tag="li" direction="up" openOnHover showTriangle>
                        <Dropdown.Toggle tag="div" className="dropdown-item media rui-navbar-avatar mnr-6">
                            <span className="media-img">{this.props.auth.name.slice(0, 2)}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                            <li>
                                <Link to="/profile" className="nav-link">
                                    <Icon name={["far", "user"]} />
                                    <span>
                                        <Trans>{this.props.auth.name}</Trans>
                                    </span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        this.setState({ changeLabModalOpen: true });
                                    }}
                                    className="dropdown-item pointer nav-link"
                                >
                                    <Icon name={["fas", "vial"]} />
                                    <span>
                                        <Trans>{this.props.auth.laboratory}</Trans>
                                    </span>
                                    <span className="rui-dropdown-circle" />
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => {
                                        this.setState({ changeRoleModalOpen: true });
                                    }}
                                    className="dropdown-item pointer nav-link"
                                >
                                    {" "}
                                    {/* role */}
                                    <Icon name={["fas", "TheaterMasks"]} size="lg" />
                                    <span>
                                        <Trans>{this.props.auth.role}</Trans>
                                    </span>
                                    <span className="rui-dropdown-circle" />
                                </div>
                            </li>

                            {/* temporary disabled until fixed */}
                            {/* <li>
                                <div className="custom-control custom-switch dropdown-item-switch">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="toggleNightMode"
                                        checked={settings.night_mode}
                                        onChange={() => {
                                            updateSettings({
                                                night_mode: !settings.night_mode,
                                            });
                                        }}
                                    />
                                    eslint-disable-next-line
                                     <label className="dropdown-item custom-control-label" htmlFor="toggleNightMode">
                                        <Icon name="moon" />
                                        <span><Trans>Night Mode</Trans></span>
                                        <span className="rui-dropdown-circle" />
                                    </label> 
                                </div>
                            </li> */}

                            <li>
                                <Link to="/sign-in" className="nav-link" onClick={this.logOut}>
                                    <Icon name="log-out" />
                                    <span>
                                        <Trans>Log Out</Trans>
                                    </span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    ""
                )}
            </>
        );
    }

    render() {
        const { settings } = this.props;

        const { mobileMenuShow } = this.state;

        return (
            <>
                {/* Navbar Data Func */}
                <div id="navbar-data" style={{ display: "none" }}></div>

                {/* Nav Menu */}
                <nav
                    className={classnames(
                        "rui-navbar rui-navbar-top",
                        settings.nav_dark ? "rui-navbar-dark" : "",
                        settings.nav_sticky ? "rui-navbar-sticky" : "",
                        settings.nav_fixed ? "rui-navbar-fixed" : "",
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : ""
                    )}
                >
                    <div className="rui-navbar-brand">
                        {settings.nav_logo_path ? (
                            <Link to={settings.nav_logo_url} className="rui-navbar-logo">
                                <img
                                    src={settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path}
                                    alt=""
                                    width={settings.nav_logo_width}
                                />
                            </Link>
                        ) : (
                            ""
                        )}

                        <button className="yay-toggle rui-yaybar-toggle" type="button">
                            <span />
                        </button>
                    </div>
                    <div className={`container${settings.nav_container_fluid ? "-fluid" : ""}`}>
                        <div className="rui-navbar-content">
                            <ul className="nav">
                                <NavbarShortcuts cache={uuid()} static={this.state.static} />
                                {this.renderSubmenus(settings.navigation)}
                            </ul>
                            <ul className="nav rui-navbar-right">{this.renderRightMenuItems()}</ul>
                        </div>
                    </div>
                </nav>

                {/* Mobile Menu */}
                <nav
                    className={classnames(
                        "rui-navbar rui-navbar-mobile",
                        settings.nav_dark ? "rui-navbar-dark" : "",
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : "",
                        mobileMenuShow ? "rui-navbar-show" : ""
                    )}
                >
                    <div className="rui-navbar-head">
                        {!mobileMenuShow ? (
                            <button
                                className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle"
                                type="button"
                                aria-label="Toggle side navigation"
                            >
                                <span />
                            </button>
                        ) : (
                            ""
                        )}
                        {settings.nav_logo_path ? (
                            <Link to={settings.nav_logo_url} className="rui-navbar-logo mr-auto">
                                <img
                                    src={settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path}
                                    alt=""
                                    width={settings.nav_logo_width}
                                />
                            </Link>
                        ) : (
                            ""
                        )}

                        <Dropdown tag="div" direction="up" showTriangle>
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar">
                                <img src={settings.img_country.poland} alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="plus-circle" />
                                        <span>Create new Post</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="users" />
                                        <span>Manage Users</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="check-circle" />
                                        <span>Check Updates</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="nav-link" onClick={this.logOut}>
                                        <Icon name="log-out" />
                                        <span>Log Out</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button
                            className="navbar-toggler rui-navbar-toggle ml-5"
                            onClick={() => {
                                this.setState({
                                    mobileMenuShow: !mobileMenuShow,
                                });
                            }}
                        >
                            <span />
                        </button>
                    </div>
                    <Collapse isOpen={mobileMenuShow} className="navbar-collapse ">
                        <div className="rui-navbar-content">
                            <ul className="nav">
                                {/* 
                                    // This is a problem since mobile navbar is a separate element
                                    // rather than one element transforming itself, to-do?
                                    <NavbarShortcuts cache={ uuid() } static={this.state.static}/> 
                                */}
                                {this.renderSubmenus(settings.navigation, true)}
                                {this.renderRightMenuItems(true)}
                            </ul>
                        </div>
                    </Collapse>
                </nav>
                <div
                    className="rui-navbar-bg"
                    onClick={() => {
                        this.setState({
                            mobileMenuShow: !mobileMenuShow,
                        });
                    }}
                    onKeyUp={() => {}}
                    role="button"
                    tabIndex={0}
                />
            </>
        );
    }
}

export default withTranslation()(
    connect(
        ({ settings, auth, options }) => ({
            settings,
            auth,
            options,
        }),
        {
            updateAuth: actionUpdateAuth,
            updateStyles,
            updateOptions: actionUpdateOptions,
            updateSettings: actionUpdateSettings,
            updatePermissions: actionUpdatePermissions,
            updateLanguage: actionUpdateLanguage,
            logOutReducer: logOutReducer,
        }
    )(PageNavbar)
);

const IdleTimer = () => {
    useIdleTimer({ onLogOut: () => console.log("logout") });

    return <></>;
};
