import React from "react";

import classNames from "classnames/dedupe";
import { Link } from "react-router-dom";

import Icon from "components/icon";

interface ITileProps {
    app: {
        url: string;
        icon: string[] | string;
        name: string;
    };
    className?: string;
}

export const Tile = (props: ITileProps) => {
    const { app, className = "" } = props;
    return (
        <Link to={app.url} className={classNames("bs-tile", className)}>
            <div className="tile-back-style">
                <Icon className="fa-7x" name={app.icon} />
            </div>
            <div className="tile-bottom-style font-weight-bold">{app.name}</div>
        </Link>
    );
};
