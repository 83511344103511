import React, { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import Axios from "axios";

import { ISelectChoicesObj } from "components/prime-data-table/interfaces";
import { getFilterOptions } from "components/prime-data-table/helpers";
import { FilterList } from "./components/filter-list";
import { handleRequest } from "utils/handleRequest";
import { useFetchData } from "hooks/useFetchData";
import { Button } from "./components/button";
import { GT } from "components/index";

import "./role-filters.styles.scss";
import { unstable_batchedUpdates } from "react-dom";

interface IModalRoleFilters {
    columns: any[];
    tableName: string;
    selectChoices: ISelectChoicesObj | null | undefined;
    closeModal: () => void;
}

export interface IFormData {
    field?: string;
    lookup?: string;
    value?: string;
    roles?: number[];
}

export const ModalRoleFilters = (props: IModalRoleFilters) => {
    const { closeModal, columns, tableName, selectChoices } = props;

    const [formData, setFormData] = useState<IFormData>();
    const [filters, setFilters] = useState<any>([]);

    const { t } = useTranslation();

    const { data, setData } = useFetchData<any[]>({ url: "bs/column-config-get-filters", params: { tableName } });

    const { data: rolesOptions } = useFetchData<ILabelValue<number>[]>({ url: "all_roles_form_data" });

    const fieldNames: ILabelValue<string>[] = useMemo(
        () =>
            columns
                .map((column) => (column.filterBody ? { label: column.header, value: column.field } : null))
                .filter((item) => item != null) as ILabelValue<string>[],
        []
    );

    const selectedColumn = useMemo(() => columns.find((column) => formData?.field == column.field), [formData?.field]);

    const FIELD_TYPE_TO_FORM_FIELD = {
        select: "primeSelect",
        checkbox: "checkbox",
        text: "input",
        number: "numeric",
        multiSelect: "multiSelect",
        date: "dateTime",
    };

    const filterTypes = getFilterOptions(selectedColumn?.filterBody?.name);

    const fields: IFormField[] = [
        {
            fieldType: "primeSelect",
            name: "field",
            label: "Field",
            options: fieldNames,
            extraData: { filterType: undefined, value: undefined },
            props: {
                translateOptions: true,
            },
        },
        {
            fieldType: "primeSelect",
            name: "lookup",
            label: "Type",
            options: filterTypes,
            disabled: !selectedColumn,
        },
        {
            fieldType: FIELD_TYPE_TO_FORM_FIELD[selectedColumn?.filterBody?.name || ""] || "input",
            name: "value",
            label: "filters>>Value",
            disabled: !selectedColumn,
            options: selectChoices?.[formData?.field || ""],
            props: {
                translateOptions: true,
                selectionMode: formData?.lookup == "__range" ? "range" : undefined,
                showTime: false,
            },
        },
    ];

    const rolesField: IFormField[] = [
        {
            fieldType: "multiSelect",
            name: "roles",
            label: "Roles",
            options: rolesOptions as ILabelValue<number>[],
        },
    ];

    const handleAddFilter = () => {
        setFilters((prev) => [
            ...prev,
            {
                field: formData?.field,
                lookup: formData?.lookup,
                value: formData?.value,
                fieldLabel: fieldNames.find((fieldName) => fieldName.value == formData?.field)?.label,
                lookupLabel: filterTypes.find((lookup) => lookup.value == formData?.lookup)?.label,
                label: selectChoices?.[formData?.field || ""]?.find((choice) => choice.value == formData?.value)?.label ?? formData?.value,
            },
        ]);
        setFormData((prev) => ({ roles: prev?.roles }));
    };

    const handleDeleteFilter = async (roleId) => {
        await handleRequest({
            request: () =>
                Axios.post("bs/column-config-multiple", {
                    tableName,
                    roles: [roleId],
                    filters: "",
                }),
            onSuccess: () => {
                setData((prev) => prev?.filter((filter) => filter.role.id != roleId));
            },
        });
    };

    const handleSubmit = async () => {
        const newFilters = filters.map((filter) => ({
            field: filter?.field,
            lookup: filter?.lookup,
            value: filter?.value,
        }));

        await handleRequest({
            request: () =>
                Axios.post("bs/column-config-multiple", {
                    tableName,
                    roles: formData?.roles,
                    filters: filters.map((filter) => ({
                        field: filter?.field,
                        lookup: filter?.lookup,
                        value: filter?.value,
                    })),
                }),
            onSuccess: () => {
                unstable_batchedUpdates(() => {
                    setData((prev) => {
                        const dataCopy = prev?.filter((filter) => !formData?.roles?.includes(filter.role.id)) || [];

                        const overwriteData =
                            formData?.roles?.map((role) => ({
                                role: { id: role, name: rolesOptions?.find((option) => role == option.value)?.label },
                                filters: newFilters,
                            })) || [];

                        return [...dataCopy, ...overwriteData];
                    });
                    setFilters([]);
                    setFormData(undefined);
                });
            },
        });
    };

    return (
        <Modal isOpen fade toggle={closeModal}>
            <GT.ModalHeader closeModal={closeModal} name="Filters per role - configuration" />
            <ModalBody>
                <GT.FormFields formData={formData} setFormData={setFormData} fields={fields} />
                <div>
                    <Button
                        onClick={handleAddFilter}
                        disabled={!formData?.value || !formData?.lookup}
                        icon={"plus-circle"}
                        text="Add filter"
                    />

                    <div className="config-block">
                        <GT.FormFields formData={formData} setFormData={setFormData} fields={rolesField} />
                        <FilterList lookups={filterTypes} fieldNames={fieldNames} selectChoices={selectChoices} filters={filters} />
                        <Button onClick={handleSubmit} disabled={!filters?.length || !formData?.roles?.length} icon={"save"} text="Save" />
                    </div>

                    <div className="applied-filters-label">{t("Applied filters:")}</div>
                    {data?.map((appliedFilter) =>
                        !!appliedFilter.filters ? (
                            <>
                                <div className="role-title">{appliedFilter.role.name}</div>
                                <FilterList
                                    lookups={filterTypes}
                                    fieldNames={fieldNames}
                                    selectChoices={selectChoices}
                                    filters={appliedFilter.filters}
                                />
                                <Button onClick={() => handleDeleteFilter(appliedFilter.role.id)} icon={"trash"} text="Delete" />
                            </>
                        ) : (
                            <></>
                        )
                    )}
                </div>
            </ModalBody>
            <GT.ModalFooter closeModal={closeModal} cancelName="Close" />
        </Modal>
    );
};
