import React, { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import Axios from "axios";
import _ from "lodash";

import { useFetchData } from "hooks/useFetchData";
import { Notification } from "./notification";
import { Icon, NavbarDropdownToggle } from "..";
import addToast from "utils/addToast";

import "./notifications-dropdown.styles.scss";

export interface INotification {
    id: number;
    isActive: boolean;
    deactivatedBy: null;
    title: string;
    content: string;
    createdDt: string;
    redirectionPath: string;
    instances: number[];
}

export const NotificationsDropdown = (props) => {
    const { data, setData } = useFetchData<{ data: INotification[] }>({
        url: "notifications",
        params: { noPagination: true, search_field: "is_active", search: "True" },
    });

    const { t } = useTranslation();

    const baseUrl = (Axios.defaults.baseURL || "").split("//");
    const url = baseUrl[baseUrl.length - 1].replace("/api", "").replace("/admin", "");
    const webSocketProtocol = window.location.protocol.includes("https") ? "wss" : "ws";

    const webSocket = useRef<WebSocket | null>(null);

    useEffect(() => {
        let socket;

        try {
            socket = new WebSocket(`${webSocketProtocol}://${url}/ws/notifications/`);
        } catch (err: any) {
            console.error(err);
        }

        if (socket) {
            socket.onmessage = async (e) => {
                const newMessage = JSON.parse(e?.data)?.content?.payload;

                addToast({ title: newMessage.title, content: newMessage.content });

                setData((prev) => ({ data: [_.mapKeys(newMessage, (value, key) => _.camelCase(key)), ...(prev?.data || [])] }));
            };

            socket.onclose = function (e) {
                console.error("notificationSocket closed unexpectedly");
            };
        }
        webSocket.current = socket;
        return () => socket?.close();
    }, []);

    return (
        <NavbarDropdownToggle quantity={data?.data?.length} icon={"bell"}>
            {(closeDropdown, ref) => (
                <div className="gt-notifications-container" ref={ref}>
                    <div className="triangle"></div>
                    <div className="gt-notifications">
                        <header className="notifications-header">
                            <span>{t("Notifications")}</span>
                            <div role="button" className="mark-as-read-btn">
                                {t("Show all")}
                            </div>
                        </header>
                        {data?.data?.length ? (
                            <div className="notifications-list">
                                {data?.data?.map((notification, index) => (
                                    <Notification key={index} notification={notification} setData={setData} closeDropdown={closeDropdown} />
                                ))}
                            </div>
                        ) : (
                            <div className="no-notifications">
                                <Icon name={["fas", "bell-slash"]} />
                                <span>{t("No notifications yet")}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </NavbarDropdownToggle>
    );
};
