import React from "react";

import { useTranslation } from "react-i18next";

import { ISelectChoicesObj } from "components/prime-data-table/interfaces";

interface FilterListProps {
    lookups: any[];
    fieldNames: any[];
    selectChoices: ISelectChoicesObj | null | undefined;
    filters: any[];
}

export const FilterList = (props: FilterListProps) => {
    const { lookups, fieldNames, selectChoices, filters } = props;

    const { t } = useTranslation();

    const getToRepresentation = (field, lookup, value) => {
        return [
            fieldNames.find((fieldName) => fieldName.value == field)?.label,
            lookups.find((l) => l.value == lookup)?.label,
            selectChoices?.[field || ""]?.find((choice) => choice.value == value)?.label ?? value,
        ];
    };

    return (
        <ul className="filters-list">
            {filters.map((filter) => {
                const [fieldLabel, lookupLabel, label] = getToRepresentation(filter.field, filter.lookup, filter.value);

                return (
                    <li>
                        <div className="filter-block">{t(fieldLabel)}</div>
                        <div className="filter-block">{t(lookupLabel)}</div>
                        <div className="filter-block">{t(label)}</div>
                    </li>
                );
            })}
        </ul>
    );
};
