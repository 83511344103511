import React, { useEffect, useState } from "react";

import { Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

import { PrimeMultiSelect } from "components/prime-multi-select/prime-multi-select";
import { IGeneratedPrimeTableColumn } from "../../interfaces/table-interfaces";
import { I } from "components/prime-data-table";
import { GT } from "components/index";

interface IHideColumns {
    defaultColumns: IGeneratedPrimeTableColumn[];
    visibleColumns: IGeneratedPrimeTableColumn[];
    handleHideColumns: I.OnHideColumnsType;
    closeModal: () => void;
}

export interface IVisibleColumn extends IGeneratedPrimeTableColumn {
    index?: number;
}

export const HideColumnsModal = ({ defaultColumns, visibleColumns, handleHideColumns, closeModal }: IHideColumns) => {
    const { t } = useTranslation();

    const [selectedVisibleColumns, setSelectedVisibleColumns] = useState<string[]>(visibleColumns.map((col) => col.field));

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        handleHideColumns(selectedVisibleColumns);
        closeModal();
    };

    return (
        <Modal isOpen={true} fade toggle={closeModal}>
            <GT.Form permCode="" disableTransactions onSubmit={handleSave}>
                <GT.ModalHeader closeModal={closeModal} name="Columns settings" />
                <ModalBody>
                    <PrimeMultiSelect
                        className="custom-search-table-input"
                        value={selectedVisibleColumns}
                        options={defaultColumns}
                        optionLabel="header"
                        optionValue="field"
                        onChange={(e) => setSelectedVisibleColumns(e.target.value)}
                        placeholder={`${t("Select columns")}`}
                        maxSelectedLabels={0}
                        selectedItemsLabel={`${t("items selected", { count: selectedVisibleColumns.length })}`}
                        filter
                    />
                </ModalBody>
                <GT.ModalFooter closeModal={closeModal} okName="Save" cancelName="Close" okIsDisabled={selectedVisibleColumns.length < 1} />
            </GT.Form>
        </Modal>
    );
};
