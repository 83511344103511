import React, { Component, memo } from "react";
import { deepCompare } from "utils/memo/deepCompare";

const arePropsEqual = (prev, next) => {
    return (
        prev.inputData.onFilterChange == next.inputData.onFilterChange &&
        prev.inputData.filterArrayLength == next.inputData.filterArrayLength &&
        prev.inputData.filter?.value == next.inputData.filter?.value &&
        deepCompare(prev.inputData.filterChoices, next.inputData.filterChoices)
    );
};

export const memoFilter = (component) => memo<any>(component, arePropsEqual);
