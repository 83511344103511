import { useCallback, useEffect, useState, useRef } from "react";

import { useLocation } from "react-router-dom";
import Axios, { CancelToken } from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { addToTableRequest, patchTableRequest } from "utils/tableOperations";
import { updateServerConnection } from "actions/index";
import { useDebounce } from "./useDebounce";

interface IUseFetchTableData<T> {
    url: string | undefined;
    triggerValues?: any[];
    initParams?: string;
    shouldFetch?: any;
    additionalParams?: string;
    configParams?: any;
    groupedOnInit?: boolean;
    pendingDependencies?: any[];
    mapData?: (data: T[]) => any;
    onUnmount?: () => void;
    onSuccess?: (data: T[]) => any;
    onError?: (err: any) => void;
}

interface IGetData {
    token?: CancelToken;
    urlParams?: string;
    extraParams?: Object;
}

const REFRESH_REQUEST_TIME = 3000;

let refreshCounter: number = 0;

let isMounted: boolean = false;

//! TODO:
//! There is no pending state, that may cause to unexpected situations
//! when the client or the server connection is slow
export function useFetchTableData<T = any, K = T>({
    url,
    triggerValues = [],
    initParams = "?pageSize=15&page=1",
    shouldFetch = undefined,
    additionalParams = "",
    configParams = undefined,
    groupedOnInit = false,
    pendingDependencies = [],
    mapData,
    onSuccess,
    onUnmount = undefined,
    onError = undefined,
}: IUseFetchTableData<T>): TableData<K> {
    const [data, setData] = useState<IData<K>>(undefined);
    const [urlParams, setParams] = useState<string>(initParams);
    const [isPending, setIsPending] = useState<boolean>(false);

    const { state } = useLocation<{ instanceId: number }>();
    const source = useRef<any>(null);

    const dispatch = useDispatch();

    const { isRefreshing } = useSelector((state) => ({ isRefreshing: state.serverConnection.isRefreshing }));

    // MAIN FETCHING FUNCTION
    const getData = async ({ token, extraParams, urlParams }: IGetData) => {
        if (groupedOnInit && !urlParams?.includes("grouping=")) return;

        let request = { ...configParams };

        if (!url) {
            setData({ data: [], meta: undefined });
            onSuccess && onSuccess([]);
            setIsPending(false);
            return;
        }

        const locationParams = state?.instanceId ? `&search=${state?.instanceId}&search_field=id` : "";
        try {
            const response = await Axios.get(url + urlParams + additionalParams + locationParams, {
                ...request,
                params: {
                    ...request?.params,
                    ...extraParams,
                },
                cancelToken: token,
            });

            const data = response.data.data ? response.data.data : response.data;
            const dataToSet = mapData ? mapData(data) : data;

            setData({ ...response.data, data: dataToSet, meta: response.data.meta });
            onSuccess && onSuccess(dataToSet);

            if (isRefreshing) {
                dispatch(updateServerConnection({ isRefreshing: false, isConnected: true }));
                refreshCounter = 0;
            }
            setIsPending(false);
            return response;
        } catch (err: any) {
            console.error(err);

            !err?.__CANCEL__ && setIsPending(false);

            onError && onError(err);

            if (err.message == "Network Error" && navigator.onLine) {
                if (refreshCounter == 10) dispatch(updateServerConnection({ isConnected: false }));
                else {
                    if (!isRefreshing) dispatch(updateServerConnection({ isRefreshing: true, isConnected: true }));
                    setTimeout(() => {
                        refreshCounter += 1;
                        return refreshData();
                    }, REFRESH_REQUEST_TIME);
                }
            } else return err;
        }
    };

    useEffect(() => {
        setIsPending(true);
    }, [...pendingDependencies, ...triggerValues]);

    useEffect(() => {
        if (!(shouldFetch != false)) return;
        source.current = Axios.CancelToken.source();

        isMounted = true;

        getData({ token: source.current.token, urlParams });
        return () => {
            source.current.cancel();
        };
    }, [...triggerValues, shouldFetch]);

    // Function refresh makes it a little bit harder to maintain but it is far more optimal
    // and that way we can await for the refresh before closing modals
    //! When passing refresh data with a useRef hook do not pass it directly to props like
    //! refresh={refreshRef?.current?.refresh} but do refreshRef={refreshRef} to keep the reference
    //! otherwise it might cause bugs
    const refreshData = async (extraParams?: Object) => {
        await getData({ extraParams, urlParams, token: source.current.token });
    };

    // Helper function that it is used to add record to table from the post response
    const addRecord = useCallback(
        ({ newRecord, customUrl, parseResponse, onResponse }: IAddRecord) =>
            addToTableRequest(customUrl ?? url, newRecord, setData, parseResponse, onResponse),
        [url]
    );

    // Helper function that it is used to modify record to from the patch response
    const patchRecord = useCallback(
        ({ newRecord, customUrl, recordId, onResponse }: IPatchRecord) =>
            patchTableRequest(customUrl ?? url + `/${recordId}`, newRecord, setData, onResponse),
        [url]
    );

    // Helper function that it is used to refresh data with specified params
    //? Fixed bugs: memoized filters has to get the newest version of handle reload to work properly
    const handleReload = useCallback(
        async (params?: string, refresh?: boolean) => {
            if (refresh) await refreshData();
            else if (params) {
                setParams(params);
                setIsPending(true);

                const response = await getData({ urlParams: params, token: source.current.token });

                return response;
            }
        },
        [url, additionalParams, JSON.stringify(configParams)]
    );

    useEffect(() => {
        return () => {
            onUnmount && onUnmount();
        };
    }, []);

    return { data, isPending, setData, refreshData, handleReload, addRecord, patchRecord, urlParams };
}
