import { Modal, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";

import CustomModalHeader from "../../../custom-modal-elements/custom-modal-header";
import CustomModalFooter from "../../../custom-modal-elements/custom-modal-footer";
import { DataTableSortMeta, IGeneratedPrimeTableColumn } from "../../interfaces/table-interfaces";
import { getColumnToGroupBy } from "../../helpers/context-menu-helpers";
import CustomForm from "../../../custom-form/custom-form";
import { I } from "components/prime-data-table";

interface IProps {
    multiSort?: DataTableSortMeta;
    visibleColumns: IGeneratedPrimeTableColumn[];
    closeModal: () => void;
    handleGroupBy?: I.OnGroupByType;
}
export const PrimeColumnGroupModal = ({ multiSort, visibleColumns, closeModal, handleGroupBy }: IProps) => {
    const { t } = useTranslation();

    const [selectedToGroupBy, setSelectedToGroupBy] = useState<string | undefined>(undefined);
    const [options, setOptions] = useState<IGeneratedPrimeTableColumn[] | undefined>(undefined);

    useEffect(() => {
        setOptions(visibleColumns.filter((col) => col.sortable));
        if (multiSort?.field && multiSort.field != selectedToGroupBy) setSelectedToGroupBy(multiSort?.field);
    }, []);

    const handleSubmitData = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!options || !selectedToGroupBy) return;
        const groupBy = getColumnToGroupBy(options, selectedToGroupBy);
        if (groupBy) {
            handleGroupBy && handleGroupBy(groupBy.field);
            closeModal();
        }
    };

    return (
        <Modal isOpen={true && !!options} toggle={closeModal} fade>
            <CustomForm permCode="" disableTransactions onSubmit={handleSubmitData}>
                <CustomModalHeader closeModal={closeModal} name="Group by" />
                <ModalBody>
                    <Dropdown
                        value={selectedToGroupBy}
                        options={options}
                        onChange={(e) => setSelectedToGroupBy(e.target.value)}
                        optionValue="field"
                        optionLabel="header"
                        className="custom-search-table-input"
                        placeholder={`${t("Select column")}`}
                        filter
                        showClear
                    />
                </ModalBody>
                <CustomModalFooter closeModal={closeModal} cancelName="Cancel" okName="Ok" />
            </CustomForm>
        </Modal>
    );
};
